<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input placeholder="文件名" v-model="form.designCode" style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button-group>
                <a-tooltip>
                  <template slot="title"> 上传 </template>
                  <a-button icon="upload" @click="upload"></a-button>
                </a-tooltip>
              </a-button-group>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table bordered :data-source="list" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
              }">
        <a-table-column title="文件类型">
          <template slot-scope="text">
            <span>
              {{ text.name }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="文件名" width="240px">
          <template slot-scope="text">
            <span>
              {{ text.name }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="上传者">
          <template slot-scope="text">
            <span>
              {{ text.name }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="上传时间">
          <template slot-scope="text">
            <span>
              {{ text.name }}
            </span>
          </template>
        </a-table-column>

        <a-table-column align="right" width="120px" fixed="right">
          <div style="padding-right: 7px" slot="title">操作</div>
          <template slot-scope="text">
            <a href="#" @click="preview(text)">预览</a>
            <a href="#">下载</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, check, fetchDownloadFile } from "@/api/project";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
export default {
  components: {},

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      id: null,
      name: null,
      subPackages: [], // 分配需要的分包信息，用来算技术3的成本
      visible: false, // 分配弹窗

      addSonProjectVisible: false, // 增加子项目弹窗
      activeProject: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    contractStatusList() {
      return this.findDataDict("contractType");
    },
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.form = {
        contractId: id,
      };
    }

    // this.getList();
  },

  methods: {
    upload() {
      this.$router.push(this.$route.path + "/add");
    },

    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(
              res.list.map((item) => {
                return {
                  ...item,
                  key: item.id,
                  children: Array.isArray(item.splitProjectList)
                    ? item.splitProjectList.map((element) => {
                        return {
                          ...element,
                          key: element.id,
                        };
                      })
                    : undefined,
                };
              })
            );
            this.expandedRowKeys = this.list
              .filter((item) => item.children)
              .map((item) => item.key);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 判断当前项目是否有子项目，当前项目有了子项目，所有的按钮都不能点了，包括拆分子项目
    hasChildren(text) {
      return !!text.children;
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.form = {
        ...this.form,
        contractId: undefined,
      };
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    configText(text) {
      check({
        id: text.contract.id,
        code: text.contract.code,
        productionMasterId: text.contract.productionMasterId,
        areaId: text.contract.areaId,
        subAreaId: text.contract.subAreaId,
        productionUnitId: text.contract.productionUnitId,
      })
        .then((res) => {
          if (res) {
            const that = this;
            this.$confirm({
              title: res + "，是否前往合同管理进行配置？",
              onOk() {
                that.$router.push(`/produce/contract?code=${text.designCode}`);
              },
            });
          } else {
            this.$router.push(`${this.$route.path}/config?id=${text.id}`);
          }
        })
        .catch(() => {});
    },

    // 打开分配弹窗
    allocate(text) {
      this.$router.push(this.$route.path + "/allocate?id=" + text.id);
    },
    setVisible(visible) {
      this.visible = visible;
    },

    // 导出
    download() {
      fetchDownloadFile(this.form)
        .then((blob) => {
          saveAs(blob, "项目收支明细");
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.download {
  float: right;
}

.column-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.primary-text {
  white-space: normal;
}

.help-text {
  font-size: 11px;
  color: rgb(138, 136, 136);
  margin-top: 2px;
}
</style>